<template lang="pug">
.content
  section.container-fluid.m-t-20

    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item
        router-link(to="/post") 动态管理
      a-breadcrumb-item 分类管理
    
    .m-t-15.m-b-20
      h1.section-title.inline 分类管理
      a-button.m-l-15(size="small" @click="handleAdd") 新增分类

    vc-table.m-b-25(
      ref="table"
      :data="table"
      @change="handleTableChange")
    
    //- 分类标签表单
    vc-taxonomy-form(
      ref="TaxonomyForm"
      :modal="modal_form"
      @ok="() => this.$refs.table.refresh()")
</template>

<script>
//- import { mapState } from 'vuex'

import columns from '@/config/column.config'
import vcTable from '@/components/vcTable'
import vcTaxonomyForm from '@/components/vcTaxonomy/vcTaxonomyForm'

export default {
  name: 'PostCategoryManagement',
  components: {
    'vc-table': vcTable,
    'vc-taxonomy-form': vcTaxonomyForm
  },
  computed: {
    //- ...mapState({
    //-   ui: state => state.ui
    //- })
  },
  data() {
    return {
      /*********************************************************
      * @description vc-taxonomy-form 对象
      *********************************************************/
      modal_form: {
        api: {
          get: id => this.$http.get(`post_category/${id}`),
          set: params => {
            const { id } = params;
            const method = id ? 'put' : 'post';
            const url = id ? `post_category/${id}`: 'post_category';
            return this.$http[method](url, params);
          }
        },
        options: {
          taxonomy: 'post_category',
          hierarchy: true,
          sort: true,
          //- url: true,
          //- description: true,
          //- thumbnail: {
          //-   accept: ['.jpg', '.gif', '.png'],
          //-   multiple: false,
          //-   app: 'admin',
          //-   module: 'post_category',
          //-   key: 'thumbnail',
          //-   tip: '尺寸大小建议 240 × 240 像素'
          //- }
        }
      },

      // 查询条件参数
      queryParam: {},

      /*********************************************************
      * @description vc-table 表格对象
      *********************************************************/
      table: {
        loading: false,
        size: 'default',   // default | middle | small
        columns: columns.postCategory(this),
        source: [],

        api: parameter => {
          return this.$http.get('post_category', {
            params: Object.assign(parameter, this.queryParam)
          })
        },
      
        // 批量操作
        actions: [
          { label: '删除', value: 'delete' },
        ],

        // 过滤
        // filters: [],
        
        // 额外
        // extra: [],
        
        // 分页器
        pagination: {
          total: 0,
          current: 1,
          pageSize: 15,
          pageSizeOptions: ['15', '25', '35'],
        },
      },
    }
  },
  filters: {},
  methods: {
    /*********************************************************
     * @description vc-table 对象方法
     *********************************************************/
    async handleTableChange({ action }) {
      if (action) {
        switch(action.do) {
          case 'delete':
            await this.deleteDatas(action.data);
          break;
        }
      }
    },

    //- 新增/编辑操作方法
    handleAdd() {
      const route = this.$router.getRoutes().find(item => item.name === 'Post Category Add');
      if (route) this.$router.push({ name: 'Post Category Add' });

      this.$refs.TaxonomyForm.open('新增分类');
    },
    async handleEdit(id) {
      const route = this.$router.getRoutes().find(item => item.name === 'Post Category Edit');
      if (route && this.$route.name !== 'Post Category Edit') return this.$router.push({ name: 'Post Category Edit', params: { id } });
      
      this.$refs.TaxonomyForm.edit('编辑分类', id);
    },

    /*********************************************************
     * @description 删除单个/多个数据
     *********************************************************/
    async deleteData(id) {
      try {
        await this.$http.delete(`post_category/${id}`);
        this.$message.success('删除成功');
        this.$refs.table.refresh();
      } catch(err) {
        this.$message.error(err.message)
      }
    },
    async deleteDatas(ids) {
      try {
        await this.$http.delete('post_category', {
          data: { ids }
        });
        this.$message.success('删除成功');
        this.$refs.table.refresh();
      } catch(err) {
        this.$message.error(err.message)
      }
    }
  },
  created() {},
}
</script>